var is_chrome = /chrome/.test(navigator.userAgent.toLowerCase());
var eventType;
/*to detect touch device and to use the ID of accessiblemegamenu plugin */
// function isTouchDevice() {
//     if ('ontouchstart' in window) {
//         eventType = 'touchstart'; //chrome or firefox
//         $('html, body').css({ 'overflow': 'auto', '-webkit-overflow-scrolling': 'auto' });
//         return true;
//     } else if (window.navigator.pointerEnabled && !is_chrome) {
//         eventType = 'touchstart'; //IE11 +
//         $('html, body').css({ 'overflow': 'auto', '-webkit-overflow-scrolling': 'auto' });
//         return true;
//     } else if (window.navigator.msPointerEnabled) {
//         eventType = 'touchstart'; //IE10
//         $('html, body').css({ 'overflow': 'auto', '-webkit-overflow-scrolling': 'auto' });
//         return true;
//     } else {
//         $('html,body').css({ 'overflow': 'none', '-webkit-overflow-scrolling': 'none' });
//         return false;
//     }
// }
/*fix for MLS-256 and 257*/
function preventClickandMouseout(elem, e) {
    if (!is_chrome && (e.type == "touchstart" || e.type == "pointerdown")) {
        $(elem).on("click mouseout", function (event) {
            event.preventDefault();
            event.stopPropagation();
        });
    }
}
function updateOrientation(e) {
    $(".ui-autocomplete").hide();
}

$(document).ready(function () {

    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        $("input[type='text']").each(function (index, data) {
            $(this).css({ 'text-transform': 'none' });
        });
        $('head').append('<link rel="stylesheet" href="/Contents/css/iosStyle.min.css" type="text/css" />');
    }

    // if (!isTouchDevice()) {
    //     //alert("ok");	
    //     $("#nav-wrapper").accessibleMegaMenu({
    //         /* prefix for generated unique id attributes, which are required 
    //            to indicate aria-owns, aria-controls and aria-labelledby */
    //         uuidPrefix: "accessible-megamenu",

    //         /* css class used to define the megamenu styling */
    //         menuClass: "nav-main-link",

    //         /* css class for a top-level navigation item in the megamenu */
    //         topNavItemClass: "nav-item",

    //         /* css class for a megamenu panel */
    //         panelClass: "sub-menu",

    //         /* css class for the hover state */
    //         hoverClass: "hover"
    //     });
    // }

    /* for pad touch */

    // if (isTouchDevice()) {
    //     window.addEventListener("orientationchange", updateOrientation);


    //     //alert("yes");
    //     var tapped = false
    //     $('#nav-wrapper ul.nav-main-link > li >  a').on(eventType, function (e) {
    //         e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    //         preventClickandMouseout(this, e);
    //         megamenuFirstLevel(this);
    //         if (!tapped) {
    //             tapped = setTimeout(function () {
    //                 tapped = null
    //             }, 300);
    //         } else {
    //             $(this).removeClass("open");
    //             $(this).closest("li").find(".sub-menu").removeClass("open");
    //             clearTimeout(tapped);
    //             tapped = null
    //             var link = $(this).attr('href');
    //             window.open(link, '_self');
    //             return false;
    //         }

    //         /* menu height on touch */

    //         $(".sec-level-menu-show").on('touchstart', function () {
    //             $('.sub-menu').removeAttr('style');
    //             $('.sec-level-sub-menu').css('min-height', 'inherit');
    //             var a = $(this).find(".sec-level-sub-menu").height();
    //             var b = $(this).parents(".sub-menu").find(".col-1").height();

    //             if (a > b) {
    //                 $(this).parents(".sub-menu").css("height", (a + 28) + "px");
    //             }
    //         });


    //         $(window).resize(function () {

    //             $('.sec-level-sub-menu').css('min-height', 'inherit');
    //             $(".sub-menu").removeAttr('style');
    //             if ($(".sec-level-sub-menu:visible").innerHeight() > $(".sec-level-sub-menu:visible").parents(".sub-menu").innerHeight()) {
    //                 $(".sec-level-sub-menu:visible").parents(".sub-menu").css("height", $(".sec-level-sub-menu:visible").innerHeight());
    //             }
    //             else {
    //                 $(".sec-level-sub-menu:visible").parents(".sub-menu").css("height", "auto");
    //             }

    //         });
    //         /* menu height on touch */

    //     });


    //     /* new add for second level menu start */
    //     $('#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li.sec-level-menu-show > a').on(eventType, function (e) {
    //         e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    //         preventClickandMouseout(this, e);
    //         megamenuSecondLevel(this);

    //         if (!tapped) {
    //             tapped = setTimeout(function () {
    //                 tapped = null;
    //             }, 300);
    //         } else {
    //             $(this).removeClass("hover");
    //             $(this).closest("li").find(".sec-level-sub-menu").hide();
    //             clearTimeout(tapped);
    //             tapped = null;
    //             var link = $(this).attr('href');
    //             window.open(link, '_self');
    //             return false;
    //         }
    //     });

    //     /* end */

    //     /* new add for third level menu start */
    //     $('#nav-wrapper ul.nav-main-link > li > .sub-menu .col-1 ul.level-1 > li > ul.level-2 > li.sec-level-menu-show > a').on(eventType, function (e) {
    //         e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    //         preventClickandMouseout(this, e);
    //         megamenuThirdLevel(this);

    //         if (!tapped) {
    //             tapped = setTimeout(function () {
    //                 tapped = null
    //             }, 300);
    //         } else {
    //             $(this).removeClass("hover");
    //             $(this).closest("li").find(".sec-level-sub-menu").hide();
    //             clearTimeout(tapped);
    //             tapped = null
    //             var link = $(this).attr('href');
    //             window.open(link, '_self');
    //             return false;
    //         }
    //     });

    //     $('.col-block.first .padding-10 ul li.no-child a').on("mouseenter", function (e) {
    //         $('.sec-level-sub-menu .col-block ul li.sub-level-menu-show a').removeClass('hover');
    //         $('.last .padding-10 .hoverDiv').css("display", "none");
    //         e.stopPropagation();
    //     });
    //     $('.col-block.last ul li').on("mouseenter", function (e) {
    //         $('.sec-level-sub-menu .col-block ul li.sub-level-menu-show a').removeClass('hover');
    //         e.stopPropagation();
    //     });
    //     /*Fourth Level - (e.g under Asia Pacific, North America */
    //     $('.col-block.first .padding-10 ul li.sub-level-menu-show a').on(eventType, function (e) {
    //         e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    //         preventClickandMouseout(this, e);
    //         megamenuFourthLevel(this);
    //         if (!tapped) {
    //             tapped = setTimeout(function () {
    //                 tapped = null
    //             }, 300);
    //         } else {
    //             clearTimeout(tapped);
    //             tapped = null
    //             var link = $(this).attr('href');
    //             window.open(link, '_self');
    //             return false;
    //         }
    //     });

    //     /* For IE only - fix for MLS253 & MLS255 clicking within the wrapper */
    //     $('.sub-nav-close-btn-container, .close-wrapper, .frmSearch .block-1, .frmSearch .block-2 > .sortSel, .frmSearch .block-2 > .ui-autocomplete-input, .form-group > .col-sm-12, .form-group .col-sm-4, .form-group.text-right').on(eventType, function (e) {
    //         var hasDropdownCareers = $(this).find(".dropdown.input-halo");
    //         var hasDropdownLegacy = $(this).find(".main");
    //         if (!is_chrome && eventType == "pointerdown") {
    //             if (hasDropdownCareers.length > 0 || hasDropdownLegacy.length > 0) {
    //                 ($(this)).on("mouseout mouseleave mousedown", function (e) {
    //                     e.preventDefault();
    //                     e.stopPropagation();
    //                 });
    //             } else {
    //                 if ($('.block-2 ul.listing').css('display') == 'block' || $('.block-2').css('display') == 'block') {
    //                     $('.block-2 ul.listing').css('display', 'none');
    //                 } else if ($('.dropdown.input-halo').hasClass('open')) {
    //                     $('.dropdown.input-halo').removeClass("open");
    //                 }
    //                 preventClickandMouseout(this, e);
    //             }
    //         }
    //     });
    //     /* end */

    //     $('.icon-menu-close').on('click', function () {
    //         $("#nav-wrapper ul.nav-main-link > li > a span.menu-arrow").removeAttr('style').css('display', 'none').closest("a").removeClass("open");

    //     });

    // }
    /* end */

    /* autocomplete work on single tap start */
    var touchmove = false;
    $('.ui-autocomplete').on('touchstart', 'li.ui-menu-item', function (e) {
        touchmove = false;
    });
    $('.ui-autocomplete').on('touchmove', 'li.ui-menu-item', function (e) {
        touchmove = true;
    });

    $('.ui-autocomplete').on('touchend', 'li.ui-menu-item', function (e) {
        if (!touchmove) {
            $(this).trigger('click');
        }
        e.preventDefault();
        e.stopPropagation();
    });

    $(document).on("blur", ".ui-autocomplete-input", function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    $(document).on('click', function () {
        $(".ui-autocomplete").hide();
    });
    /* end */
});